import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/EcoClub/2024/Voice/1.jpg';
// import p2 from 'assests/Photos/Clubs/EcoClub/2024/Voice/2.jpg';
// import p3 from 'assests/Photos/Clubs/EcoClub/2024/Voice/3.jpg';
// import p4 from 'assests/Photos/Clubs/EcoClub/2024/Voice/4.jpg';

import Ecoclub2023 from 'views/EnvironmentDay2023/Ecoclub2023';

import SideHealth from 'views/HealthAndWellnesClub/SideHealth';

import { Typography } from '@mui/material';
import Container from 'components/Container';
// import EcoClubSidebar2024 from '../EnvironmentDay2023/EcoClubSidebar2024';
import Sidebar from '../EcoClub/Sidebar';
import EcoAssociationSidebar2024 from '../EnvironmentDay2023/EcoAssociationSidebar2024';


const VoiceChange2024 = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_url}/home/events-activities/association/EcoClub/Voice/1.webp`;
  const p2 = `${config.base_image_url}/home/events-activities/association/EcoClub/Voice/2.webp`;
  const p3 = `${config.base_image_url}/home/events-activities/association/EcoClub/Voice/3.webp`;
  const p4 = `${config.base_image_url}/home/events-activities/association/EcoClub/Voice/4.webp`;
 

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p3,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source:p4,
      rows: 1,
      cols: 1,
    },
   
    
  ];

  return (
    <Main>
   
<Container>

  <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center' >
      Voice for Change – Trash into Treasure
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
      Classes: 9 & 10   &  Date: 11 June 2024
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        The Eco Association organized an Activity: “Voice for Change - Trash into Treasure” for students of Classes 9 and 10 on Tuesday, 11 June 2024. The campaign was designed to raise awareness about the importance of upcycling and students were encouraged to think creatively about reusing materials.
        <br></br>
        The campaign successfully engaged a large number of students, fostering a community of environmentally conscious individuals. The models created by the students were innovative and demonstrated a high level of creativity, showcasing items such as Solar Powered Bulbs, Art pieces, and Utility Objects made from repurposed materials. The mime act was effective in conveying the message of sustainability and the importance of upcycling.
        <br></br>
        The upcycling campaign has not only educated students about sustainable practices but also equipped them with the skills to implement these practices in their daily lives. The enthusiasm and participation in the campaign reflect a growing commitment to environmental stewardship amongst the students.
       
        </Typography>
      </Box>
      <Typography
          variant={'h6'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
"Upcycling for a Brighter Future: Innovation and Sustainability Hand in Hand."

<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={2}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    </Grid>

    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <EcoAssociationSidebar2024 />
             </Box>
             <Box marginBottom={4}>
               <Sidebar />
             </Box>


    </Grid>
    </Grid> 
    </Container>
    </Main>
   
   
  );
};

export default VoiceChange2024;